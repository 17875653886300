import StartSync from 'o365.pwa.modules.client.SyncManager.ts';
import { Completer } from 'o365-utils';
import IndexedDBHandler from 'o365.pwa.modules.client.IndexedDBHandler.ts';
import { reactive, readonly } from 'vue';
import { SyncDefinition } from 'o365.pwa.modules.client.SyncDefinition.ts';
import { API } from 'o365-modules';
import { app as appConfigs } from 'o365-modules';
import { AppStepDefinition, type IAppStepDefinitionOptions } from 'o365.pwa.modules.client.steps.AppStepDefinition.ts';
import { EventEmitter, type EventsMap } from 'o365-modules';
import { getOrCreateProcedure } from 'o365-modules';
import { ToastType } from 'o365-vue-services';
import { alert } from 'o365-vue-services';
import type App from 'o365.pwa.modules.client.dexie.objectStores.App.ts';
import type PWAState from 'o365.pwa.modules.client.dexie.objectStores.PWAState.ts';
import O365ServiceWorkerRegistration from 'o365.pwa.modules.client.ServiceWorkerRegistration.ts';
import type { AppState, SyncDefinitionId } from 'o365.pwa.types.ts';
import { Procedure } from 'o365-modules';
import { ServiceWorkerState } from 'o365.pwa.modules.client.dexie.objectStores.ServiceWorkerState.ts';
import type { IServiceWorkerImportMap } from 'o365.pwa.declaration.sw.IServiceWorkerImportmap.d.ts';
import type { IServiceWorkerStateOptions } from 'o365.pwa.declaration.shared.dexie.objectStores.ServiceWorkerState.d.ts';

export interface IPWAState {
    appName: string,
    appVersion: string,
    appInitializedCompleter: Completer<void>,
    appInitialized: boolean,
    appInitializedFulfilled: boolean,
    appInitializedRejected: boolean,
    syncDefinitions: Map<string, SyncDefinition> | null;
    currentSync: SyncDefinition | null;
    pwaState: PWAState | null;
    serverCheckAbortController: AbortController | null;
    syncInOtherContext: boolean;
    serviceWorkerRegistration: O365ServiceWorkerRegistration | null;
    webSocket: WebSocket | null;
}

export interface IPWAStoreOptions {
    enableServerCheck: boolean;
}

export interface IPWAStore {
    state: IPWAState;
    initialize: (appName: string, appVersion: string, syncDefinitions: Map<string, SyncDefinition>, pwaStoreOptions?: IPWAStoreOptions) => Promise<void>;
    startSync: (syncOptionId: SyncDefinitionId, continueSync: boolean) => Promise<void>;
    installApp: (appId: string, options?: IAppStepDefinitionOptions, runWithoutUI?: boolean) => Promise<boolean>;
    updateApp: (appId: string, options?: IAppStepDefinitionOptions, runWithoutUI?: boolean) => Promise<boolean>;
    updateAppState: (newValue: AppState) => Promise<void>;
    uninstallApp: (appId: string) => Promise<void>;
    uninstallAllApps: () => Promise<void>;
    cancelSync: () => void;
    clearTables: (id: string) => Promise<void>;
    setServiceWorkerRegistration: (serviceWorkerRegistration: O365ServiceWorkerRegistration) => Promise<void>;
    clearLocalData: () => Promise<void>;
    setDebugUi: (newValue: boolean) => Promise<void>;
    clearOfflineDataProc: () => Promise<void>;
    setDebugMergeProc: (newValue: boolean) => Promise<void>;
    setSkipCheckIn: (newValue: boolean) => Promise<void>;
    testProperty: 1;
    rerunSync: (syncOptionId: SyncDefinitionId) => Promise<void>;
    checkForAppUpdate: ((appId: string, importMap?: IImportMap) => Promise<void>) & ((app: App, importMap?: IImportMap) => Promise<void>);
    checkForAppsUpdate: ((apps: Array<App>) => Promise<void>) & ((appIds: Array<string>) => Promise<void>);
    eventEmitter: EventEmitter<IPwaStoreEventsMap>;
    getServiceWorkerImportMap: () => Promise<object>;
}

export interface IImportMap {
    imports: { [key: string]: string };
    scopes: { [key: string]: { [key: string]: string } };
}

export interface IPwaStoreEventsMap extends EventsMap {
    appInstalled: (appId: string) => void;
    appInstalling: (appId: string) => void;
    appUpdated: (appId: string) => void;
    appUninstalled: (appId: string) => void;
    tablesCleared: (id: string) => void;
    allAppsUninstalled: () => void;
    appUpdateAvailable: (appId: string) => void;
    serviceWorkerUpdateAvailable: (appId: string) => void;
    syncStarting: (appId: string) => void;
    syncFinished: (syncType: string | undefined) => void;
    offlineDataCleared: () => void;
}

export const pwaStore: IPWAStore = (() => {
    const serverConnectionRecheckInterval = 10 * 1000; // 10 Seconds

    const state: IPWAState = reactive(<IPWAState>{
        appInitializedCompleter: new Completer<void>(),
        get appInitialized() {
            return state.appInitializedCompleter.state != 'Pending';
        },
        get appInitializedFulfilled() {
            return state.appInitializedCompleter.state == 'Fulfilled';
        },
        get appInitializedRejected() {
            return state.appInitializedCompleter.state == 'Rejected';
        },
        syncDefinitions: null,
        currentSync: null,
        pwaState: null,
        serverCheckAbortController: null,
        syncInOtherContext: false,
        serviceWorkerRegistration: null,
        webSocket: null,
    });

    const eventEmitter = new EventEmitter<IPwaStoreEventsMap>();

    const _privateState = {
        syncBroadcastChannel: new BroadcastChannel('PWA_Sync')
    };

    const initialize = async (appName: string, appVersion: string, syncDefinitions: Map<string, SyncDefinition>, pwaStoreOptions?: IPWAStoreOptions): Promise<void> => {
        state.appName = appName;
        state.appVersion = appVersion;
        state.syncDefinitions = syncDefinitions;

        let idbApp = await IndexedDBHandler.getApp(appConfigs.id);

        if (idbApp === null) {
            idbApp = await IndexedDBHandler.createApp(appConfigs.id);
        }

        let idbPwaState = await idbApp.pwaState;

        if (idbPwaState === null) {
            idbPwaState = await IndexedDBHandler.createPWAState(appConfigs.id, 'ONLINE', true);
        }

        let idbServiceWorkerState = await idbApp.serviceWorkerState;

        if (idbServiceWorkerState === null) {
            idbServiceWorkerState = await IndexedDBHandler.createServiceWorkerState({ appId: idbApp.id });
        }

        state.pwaState = idbPwaState;

        if (pwaStoreOptions?.enableServerCheck ?? true) {
            updateServerStatus();

            if ('connection' in navigator) {
                (navigator.connection as any)?.addEventListener('change', () => {
                    state.webSocket?.close();
                    state.webSocket = null;
                    updateServerStatus();
                });
            }
        }

        _privateState.syncBroadcastChannel.addEventListener('message', _pwaSyncOnMessage);
        _privateState.syncBroadcastChannel.addEventListener('messageerror', _pwaSyncOnMessageError);

        // TODO: Add logic to do stuff if new appVersion > state.pwaState.version

        state.appInitializedCompleter.complete();
    };

    const startSync = async (syncOptionId: SyncDefinitionId, continueSync: boolean = false): Promise<void> => {
        const serviceWorkerRegistered = state.serviceWorkerRegistration?.serviceWorkerRegistered ?? false;

        if (!serviceWorkerRegistered) {
            // TODO: Give better warning
            alert('Failed to start sync as service worker is currently not installed', ToastType.Danger, { autohide: true, delay: 5000 });
        }

        if (state.syncInOtherContext) {
            // TODO: Give better warning
            alert('Failed to start sync as another sync is currently running in another tab or window', ToastType.Danger, { autohide: true, delay: 5000 });
            return;
        }

        if (state.syncDefinitions!.has(syncOptionId) === false) {
            // TODO: Give better warning
            alert('Failed to start sync. Could not find sync options', ToastType.Danger, { autohide: true, delay: 5000 });
            return;
        }

        if (state.pwaState!.hasDatabaseConnection === false || window.navigator.onLine === false) {
            alert('Failed to start sync. Could not connect to the server', ToastType.Danger, { autohide: true, delay: 5000 });
            return;
        }

        _privateState.syncBroadcastChannel.postMessage(JSON.stringify({
            messageType: 'syncStart',
            appId: appConfigs.id,
            syncOptionId: syncOptionId,
        }));

        let syncOptions = state.syncDefinitions!.get(syncOptionId);

        if (!syncOptions) {
            throw new Error("Missing sync options.");
        }

        state.currentSync = syncOptions!;

        eventEmitter.emit('syncStarted', syncOptions?.syncType);

        let syncProgress = await StartSync(syncOptionId, state.currentSync, continueSync);

        state.currentSync = null;
        
        if (syncProgress.hasError) {
            return;
        }

        _privateState.syncBroadcastChannel.postMessage(JSON.stringify({
            messageType: 'syncEnd',
            appId: appConfigs.id,
            syncOptionId: syncOptionId,
        }));


        if (syncOptionId === 'OFFLINE-SYNC') {
            state.pwaState!.hasLocalData = true;
        } else if (syncOptionId === "ONLINE-SYNC") {
            state.pwaState!.hasLocalData = false;
        }

        state.pwaState!.lastSync[syncOptionId] = new Date();

        // TODO: Add logic to retrieve from sync definition to figure out if all user data has been synced online
        // else if (syncOptions.syncType === 'ONLINE-SYNC' && syncOptions.appStepDefintion) {
        //     state.pwaState.appOnlineSynced = true;
        // }

        await state.pwaState!.save();

        eventEmitter.emit('syncFinished', syncOptions?.syncType);
    };

    const installApp = async (appId: string, options?: IAppStepDefinitionOptions, runWithoutUI?: boolean): Promise<boolean> => {
        const installedApps = (() => {
            try {
                const installedAppsString = window.localStorage.getItem('O365-PWA-Installed-Apps') ?? '[]';

                const installedApps = new Set(JSON.parse(installedAppsString));

                installedApps.delete(appId);

                window.localStorage.setItem('O365-PWA-Installed-Apps', JSON.stringify(Array.from(installedApps)));

                return installedApps;
            } catch (reason) {
                return null;
            }
        })();

        if (installedApps === null) {
            alert('Failed to start sync. Could not find sync installed apps', ToastType.Danger, { autohide: true, delay: 5000 });
            return false;
        }

        let syncOptions = state.syncDefinitions!.get('Install-App');

        if (syncOptions === undefined) {
            alert('Failed to start sync. Could not find sync Install-App', ToastType.Danger, { autohide: true, delay: 5000 });
            return false;
        }

        if (runWithoutUI !== undefined) {
            syncOptions.runWithoutUI = runWithoutUI;
        }

        let appState = await IndexedDBHandler.getApp(appId);

        if (appState === null) {
            appState = await IndexedDBHandler.createApp(appId);
        }

        let pwaState = await appState.pwaState;

        if (pwaState === null) {
            pwaState = await IndexedDBHandler.createPWAState(appId, 'OFFLINE', false);
        }

        let serviceWorkerState = await appState.serviceWorkerState;

        const importMap = await getServiceWorkerImportMap();
        const entrypoint = options?.entrypoint;

        if (serviceWorkerState === null) {
            serviceWorkerState = await IndexedDBHandler.createServiceWorkerState(<IServiceWorkerStateOptions>{ appId, entrypoint, importMap });
        } else {
            serviceWorkerState.importMap = importMap;
        }

        await state.serviceWorkerRegistration?.unregisterServiceWorker();

        serviceWorkerState.readyForInstall = true;
        serviceWorkerState.installed = false;
        serviceWorkerState.failedToInstall = false;

        pwaState.isAppInstalled = false;

        await pwaState.save();

        await serviceWorkerState.save();
        
        let scope = "/";

        await state.serviceWorkerRegistration?.registerServiceWorker(false, scope);

        ServiceWorkerState.clearCache();

        if (state.syncInOtherContext) {
            // TODO: Give better warning
            alert('Failed to start sync as another sync is currently running in another tab or window', ToastType.Danger, { autohide: true, delay: 5000 });
            return false;
        }

        if (state.pwaState!.hasDatabaseConnection === false || window.navigator.onLine === false) {
            alert('Failed to start sync. Could not connect to the server', ToastType.Danger, { autohide: true, delay: 5000 });
            return false;
        }

        _privateState.syncBroadcastChannel.postMessage(JSON.stringify({
            messageType: 'syncStart',
            appId: appId,
            syncOptionId: `Installing app: ${appId}`,
        }));

        state.currentSync = syncOptions;

        (state.currentSync.steps[0] as AppStepDefinition).updateOptions({
            appId: appId,
            title: `Installing app: ${appId}`,
            stepId: `Installing app: ${appId}`,
            ...options
        })

        eventEmitter.emit('appInstalling', appId);

        let syncProgress = await StartSync('INSTALL-APP-SYNC', state.currentSync, false);

        state.currentSync = null;

        _privateState.syncBroadcastChannel.postMessage(JSON.stringify({
            messageType: 'syncEnd',
            appId: appId,
            syncOptionId: `Installing app: ${appId}`,
        }));

        if (syncProgress.hasError) {
            window['console'].error('ServiceWorker::Sync Error', syncProgress);

            return false;
        }

        try {
            installedApps.add(appId);
            window.localStorage.setItem('O365-PWA-Installed-Apps', JSON.stringify(Array.from(installedApps)));
        } catch (reason) {
            alert('Failed to update sync result', ToastType.Danger, { autohide: true, delay: 5000 });
            return false;
        }

        pwaState.isAppInstalled = true;
        pwaState.hasHtmlUpdate = false;
        pwaState.hasAppResourceUpdate = false;
        pwaState.hasServiceWorkerUpdate = false;

        await pwaState.save();

        installedApps.add(appId);

        eventEmitter.emit('appInstalled', appId);

        return true;
    };

    const updateApp = async (appId: string, options?: IAppStepDefinitionOptions, runWithoutUI?: boolean): Promise<boolean> => {
        const app = await IndexedDBHandler.getApp(appId);

        const pwaState = await app?.pwaState;

        if (pwaState && pwaState.hasServiceWorkerUpdate) {
            await _uninstallServiceWorker();

            const serviceWorkerScriptStates = await IndexedDBHandler.getServiceWorkerScriptStates(appId);

            for (const serviceWorkerScriptState of serviceWorkerScriptStates) {
                await serviceWorkerScriptState.delete();
            }

            pwaState.hasServiceWorkerUpdate = false;

            await pwaState.save();
        }

        const result = await installApp(appId, options, runWithoutUI);

        if (result) {
            eventEmitter.emit('appUpdated', appId);
        }

        return result;
    };

    const clearOfflineDataProc = async (): Promise<void> => {
        const userDevice = await IndexedDBHandler.getUserDevice();

        if (userDevice?.deviceRef && appConfigs.id) {
            const storedProcedure = new Procedure({ id: "CleanUpProc", procedureName: "astp_System_ClearOfflineDataByDeviceRef", timeout: 60 });
            await storedProcedure.execute({ DeviceRef: userDevice?.deviceRef, AppID: appConfigs.id });
            alert('OfflineData has been cleared.', ToastType.Success, { autohide: true, delay: 1000 });
            eventEmitter.emit('offlineDataCleared');
        }
        return;
    };

    const uninstallApp = async (appId: string): Promise<void> => {
        const app = await IndexedDBHandler.getApp(appId);
        const deviceRef = await IndexedDBHandler.getUserDevice();
        if (app) {
            const databases = await app.databases.getAll();

            for (let db of databases) {
                await db.delete();
            }
            const serviceWorkerState = await app.serviceWorkerState;
            if (serviceWorkerState) {
                const appResources = await serviceWorkerState.appResourceStates.getAll();
                for (let resource of appResources) {
                    await resource.delete();
                }

                const scriptStates = await serviceWorkerState.serviceWorkerScriptStates.getAll();
                for (let script of scriptStates) {
                    script.delete();
                }
            }

            const pwaState = await app.pwaState;
            if (pwaState) {
                pwaState.isAppInstalled = false;
                pwaState.hasAppResourceUpdate = false;
                pwaState.hasHtmlUpdate = false;
                await pwaState.save();
            }
            const clearOfflineDataProc = getOrCreateProcedure({ id: "PWAStore:clearOfflineData", procedureName: "astp_Local_PWA_ClearOfflineDataByDeviceRef" });

            const procOptions = {
                AppID: app.id,
                DeviceRef: deviceRef?.deviceRef
            };
            clearOfflineDataProc.execute(procOptions);

            eventEmitter.emit('appUninstalled', appId);
        }

        return;
    };

    const clearTables = async (id: string): Promise<void> => {
        const app = await IndexedDBHandler.getApp(id);
        if (!app) return;
        const databases = await app.databases.getAll();
        for (let database of databases) {
            const objectStores = await database.objectStores.getAll();
            for (let objStore of objectStores) {
                await objStore.delete();
            }
            await database.delete();
        }
        eventEmitter.emit('tablesCleared', id);
    };

    const uninstallAllApps = async (): Promise<void> => {
        const apps = await IndexedDBHandler.getApps();

        if (apps.length > 0) {
            for (let app of apps) {
                const databases = await app.databases.getAll();

                for (let db of databases) {
                    await db.delete();
                }
                const serviceWorkerState = await app.serviceWorkerState;
                if (serviceWorkerState) {
                    const appResources = await serviceWorkerState.appResourceStates.getAll();
                    for (let resource of appResources) {
                        await resource.delete();
                    }

                    const scriptStates = await serviceWorkerState.serviceWorkerScriptStates.getAll();
                    for (let script of scriptStates) {
                        script.delete();
                    }
                }

                const pwaState = await app.pwaState;
                if (pwaState) {
                    pwaState.isAppInstalled = false;
                    pwaState.hasAppResourceUpdate = false;
                    pwaState.hasHtmlUpdate = false;

                    await pwaState.save();
                }
            }

            eventEmitter.emit('allAppsUninstalled');
        }

        return;
    };

    const cancelSync = (): void => {
        state.currentSync?.currentSyncProgress?.markSyncAsCanceled();
    };

    const rerunSync = async (syncOptionId: SyncDefinitionId): Promise<void> => {
        await startSync(syncOptionId);
    };

    const checkForAppUpdate = (async (appIdentifier: string | App, importMap?: IImportMap): Promise<void> => {
        const app = typeof appIdentifier === 'string' ? await IndexedDBHandler.getApp(appIdentifier) : appIdentifier;

        if (app === null) {
            return;
        }

        const pwaState = await app.pwaState;

        if (pwaState === null || !pwaState.isAppInstalled || pwaState.hasAppUpdateAvailable) {
            return;
        }

        if (importMap === undefined) {
            const siteImportMap = await _getSiteImportMap();
            const libsImportMap = await _getLibsImportMap();
            const appImportMap = await _getAppImportMap(app.id);

            importMap = _mergeImportMaps(siteImportMap, libsImportMap, appImportMap);
        }

        if (await _checkForAppHtmlUpdate(app)) {
            pwaState.hasHtmlUpdate = true;

            await pwaState.save();
        }

        if (!pwaState.hasAppUpdateAvailable && await _checkForFileUpdatesInApp(app, importMap)) {
            pwaState.hasAppResourceUpdate = true;

            await pwaState.save();
        }

        if (await checkForServiceWorkerUpdate(app)) {
            pwaState.hasServiceWorkerUpdate = true;
            await pwaState.save();
        }

        if (pwaState.hasAppUpdateAvailable) {
            eventEmitter.emit('appUpdateAvailable', app.id);
        }
    }) as ((appId: string, importMap?: IImportMap) => Promise<void>) & ((app: App, importMap?: IImportMap) => Promise<void>);

    const checkForAppsUpdate = (async (appIdentifiers: Array<string | App>): Promise<void> => {
        const siteImportMap = await _getSiteImportMap();
        const libsImportMap = await _getLibsImportMap();

        for (const appIdentifier of appIdentifiers) {
            const app = typeof appIdentifier === 'string' ? await IndexedDBHandler.getApp(appIdentifier) : appIdentifier;

            if (app === null) {
                continue;
            }

            const appImportMap = await _getAppImportMap(app.id);

            const importMap = _mergeImportMaps(siteImportMap, libsImportMap, appImportMap);

            await checkForAppUpdate(app.id, importMap);
        }

        return;
    }) as ((apps: Array<App>) => Promise<void>) & ((appIds: Array<string>) => Promise<void>);

    const updateServerStatus = async (): Promise<void> => {
        if (!state.appInitialized) {
            await state.appInitializedCompleter.promise;
        }

        _connectWebSocket();
    };

    const _connectWebSocket = async (): Promise<void> => {
        if (!state.appInitialized) {
            await state.appInitializedCompleter.promise;
        }

        if (state.webSocket) {
            return;
        }

        state.webSocket = new WebSocket('/nt/api/pwa/check-connection');

        let keepAliveMessageInterval: number | null = null;

        state.webSocket.onopen = async () => {
            state.pwaState!.hasDatabaseConnection = true;
            await state.pwaState!.save();

            keepAliveMessageInterval = setInterval(() => {
                state.webSocket?.send('');
            }, 1000 * 60);
        };

        state.webSocket.addEventListener('close', async () => {
            state.webSocket = null;

            if (keepAliveMessageInterval) {
                clearInterval(keepAliveMessageInterval);
            }


            _sendPing();
        });

        state.webSocket.addEventListener('message', (_message) => { });

        state.webSocket.addEventListener('error', (_error) => {
            state.webSocket!.close();
        });
    };

    const _sendPing = async (): Promise<void> => {
        try {
            const response = await fetch('/nt/api/pwa/check-connection');

            state.pwaState!.hasDatabaseConnection = response.status === 200;
        } catch (reason) {
            console.error(reason);

            state.pwaState!.hasDatabaseConnection = false;
        } finally {
            await state.pwaState!.save();

            setTimeout(updateServerStatus, serverConnectionRecheckInterval);
        }
    };

    const updateAppState = async (newValue: AppState): Promise<void> => {
        state.pwaState!.appState = newValue;

        await state.pwaState!.save();
    };

    const setDebugUi = async (newValue: boolean): Promise<void> => {
        state.pwaState!.debugUi = newValue;

        await state.pwaState!.save();
    };

    const setDebugMergeProc = async (newValue: boolean): Promise<void> => {
        state.pwaState!.debugMergeProc = newValue;

        await state.pwaState!.save();
    };
    
    const setSkipCheckIn = async (newValue: boolean): Promise<void> => {
        state.pwaState!.skipCheckIn = newValue;

        await state.pwaState!.save();
    };

    const _pwaSyncOnMessage = (event: MessageEvent) => {
        const eventDataString = event.data;

        try {
            const eventData = JSON.parse(eventDataString);

            if (eventData.appId !== appConfigs.id && eventData.appId) {
                return;
            }

            switch (eventData.messageType) {
                case 'syncStart':
                    state.syncInOtherContext = true;
                    break;
                case 'syncEnd':
                    state.syncInOtherContext = false;
                    break;
            }
        } catch (error) {
            // TODO: Handle error
            window['console'].error('Failed to parse message data', error);
        }
    };

    const _pwaSyncOnMessageError = (event: MessageEvent) => {
        window['console'].error(event);
    };

    const setServiceWorkerRegistration = async (serviceWorkerRegistration: O365ServiceWorkerRegistration) => {
        state.serviceWorkerRegistration = serviceWorkerRegistration;

        const registeredServiceWorker = await serviceWorkerRegistration.registeredServiceWorker;

        if (registeredServiceWorker?.active ?? false) {
            let idbApp = await IndexedDBHandler.getApp(appConfigs.id);

            if (idbApp === null) {
                idbApp = await IndexedDBHandler.createApp(appConfigs.id);
            }

            let serviceWorkerState = await idbApp.serviceWorkerState;

            if (serviceWorkerState === null) {
                const importMap = await getServiceWorkerImportMap();
                await IndexedDBHandler.createServiceWorkerState(<IServiceWorkerStateOptions>{ appId: idbApp.id, entrypoint: appConfigs.config?.pwaSettings?.entrypoint, importMap });
            }
        }
    };

    const clearLocalData = async () => {
        const response = confirm('This will delete all local data. Included unsynced work.\n\nContinue?');

        if (!response) {
            return;
        }

        await Promise.all([
            _uninstallServiceWorker(),
            _clearCache(),
            _clearIndexedDB(),
        ]);

        window.location.reload();
    };

    const _clearCache = async () => {
        try {
            const cacheKeys = await caches.keys();

            for (const cacheKey of cacheKeys) {
                await caches.delete(cacheKey);
            }
        } catch (error) {
            window['console'].error(error);
        }
    };

    const _clearIndexedDB = async () => {
        try {
            const databases = await indexedDB.databases();

            const promiseList: Array<Promise<void>> = [];

            for (const database of databases) {
                promiseList.push(new Promise((resolve, reject) => {
                    if (database.name) {
                        const idbRequest = indexedDB.deleteDatabase(database.name);

                        idbRequest.addEventListener('success', () => resolve());
                        idbRequest.addEventListener('error', () => reject());
                    }

                    resolve();
                }));
            }

            await Promise.allSettled(promiseList);
        } catch (error) {
            window['console'].error(error);
        }
    };

    const _uninstallServiceWorker = async () => {
        await state.serviceWorkerRegistration?.unregisterServiceWorker();
    };

    const _getSiteImportMap = async (): Promise<IImportMap> => {
        const siteImportMap = await API.request({
            requestInfo: '/nt/api/staticfiles/import-map/site',
            method: 'GET',
            headers: new Headers({
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'X-NT-API': 'true',
                'o365-workbox-strategy': 'NetworkOnly'
            }),
            showErrorDialog: false
        });

        return siteImportMap;
    };

    const _getLibsImportMap = async (): Promise<IImportMap> => {
        const libsImportMap = await API.request({
            requestInfo: '/nt/api/staticfiles/import-map/libs',
            method: 'GET',
            headers: new Headers({
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'X-NT-API': 'true',
                'o365-workbox-strategy': 'NetworkOnly'
            }),
            showErrorDialog: false
        });

        return libsImportMap;
    };

    const _getAppImportMap = async (appId: string): Promise<IImportMap> => {
        const appImportMap = await API.request({
            requestInfo: `/nt/api/staticfiles/import-map/app/${appId}`,
            method: 'GET',
            headers: new Headers({
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'X-NT-API': 'true',
                'o365-workbox-strategy': 'NetworkOnly'
            }),
            showErrorDialog: false
        });

        return appImportMap;
    };

    const _mergeImportMaps = (...args: Array<IImportMap>): IImportMap => {
        const mergedMap: IImportMap = {
            imports: {},
            scopes: {}
        };

        for (const map of args) {
            Object.assign(mergedMap.imports, map.imports ?? {});

            for (const [scopeKey, scopeValue] of Object.entries(map.scopes ?? {})) {
                if (!mergedMap.scopes[scopeKey]) {
                    mergedMap.scopes[scopeKey] = {};
                }

                Object.assign(mergedMap.scopes[scopeKey], scopeValue);
            }
        }

        return mergedMap;
    };

    const getServiceWorkerImportMap = async (): Promise<IServiceWorkerImportMap> => {
        const appImportMap = await API.request({
            requestInfo: `/nt/service-worker/importmap`,
            method: 'GET',
            headers: new Headers({
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'X-NT-API': 'true',
                'o365-workbox-strategy': 'NetworkOnly'
            }),
            showErrorDialog: false
        });
        return appImportMap;
    };

    const checkForServiceWorkerUpdate = async (app: App): Promise<boolean | null> => {
        let hasUpdate = false;

        const serviceWorkerState = await app.serviceWorkerState;

        if (serviceWorkerState === null) return null;

        const newImportMap = await getServiceWorkerImportMap();

        const scriptStates = await serviceWorkerState.serviceWorkerScriptStates.getAll();

        for (const script of scriptStates) {
            if (script.importmapEntry.type === 'cdn') {
                continue;
            }

            const newImportMapScript = newImportMap[script.importmapEntry.name];

            if (newImportMapScript === undefined || newImportMapScript.type === 'cdn' || newImportMapScript.fingerprint !== script.importmapEntry.fingerprint) {
                if (location.host === 'dev-test.omega365.com') {
                    console.debug('ServiceWorker script update found', newImportMapScript, script.importmapEntry)
                }

                hasUpdate = true;
            }
        }

        return hasUpdate;
    };

    const _checkForFileUpdatesInApp = async (app: App, importMap: IImportMap): Promise<boolean | null> => {
        const serviceWorkerState = await app.serviceWorkerState;

        if (serviceWorkerState === null) {
            return null;
        }

        const relativeUrl = `https://${location.host}/nt/${app.id}`;

        const resources = await serviceWorkerState.appResourceStates.getAll();

        const appHasUpdates = resources.some((resource) => {
            if (
                resource.id.startsWith('https://') ||
                resource.id.startsWith('http://')
            ) {
                return false;
            }

            let filePath: string | null = null;
            let correctedAlias = resource.id;

            if (correctedAlias.startsWith('/') || correctedAlias.startsWith('./') || correctedAlias.startsWith('../')) {
                let source = _resolveRelativeUrl(correctedAlias, resource.relativeRoot ?? relativeUrl);

                let sourceUri = new URL(source);

                let sourcePath = sourceUri.pathname;

                if (resource.scope) {
                    const scopeSources = importMap.scopes[resource.scope];

                    if (scopeSources === undefined) {
                        return false;
                    }

                    let scopeSrc = scopeSources[sourcePath];

                    if (scopeSrc === undefined) {
                        return false;
                    }

                    scopeSrc = _resolveRelativeUrl(scopeSrc, resource.relativeRoot ?? relativeUrl);

                    if (resource.url !== scopeSrc.trim()) {
                        if (location.host === 'dev-test.omega365.com') {
                            console.debug('WebResource update found', resource, scopeSrc);
                        }

                        return true;
                    }
                } else {
                    let importsSrc = importMap.imports[sourcePath];

                    if (importsSrc === undefined) {
                        return false;
                    }

                    importsSrc = _resolveRelativeUrl(importsSrc, resource.relativeRoot ?? relativeUrl);

                    if (resource.url !== importsSrc) {
                        if (location.host === 'dev-test.omega365.com') {
                            console.debug('WebResource update found', resource, importsSrc);
                        }

                        return true;
                    }
                }

                return false;
            } else if (correctedAlias.includes('/')) {
                filePath = correctedAlias.split('/').slice(1).join('/');
                correctedAlias = correctedAlias.split('/')[0] + '/';
            }

            if (resource.scope) {
                const scopeSources = importMap.scopes[resource.scope];

                if (scopeSources === undefined) {
                    return false;
                }

                let scopeSrc = scopeSources[correctedAlias];

                if (scopeSrc === undefined) {
                    return false;
                }

                
                if (filePath) {
                    scopeSrc += filePath;
                }

                scopeSrc = _resolveRelativeUrl(scopeSrc, relativeUrl);

                if (resource.url !== scopeSrc) {
                    if (location.host === 'dev-test.omega365.com') {
                        console.debug('WebResource update found', resource, scopeSrc);
                    }

                    return true;
                }
            } else {
                let importsSrc = importMap.imports[correctedAlias];

                if (importsSrc === undefined) {
                    return false;
                }

                if (filePath) {
                    importsSrc += filePath;
                }

                importsSrc = _resolveRelativeUrl(importsSrc, relativeUrl);

                if (resource.url !== importsSrc) {
                    if (location.host === 'dev-test.omega365.com') {
                        console.debug('WebResource update found', resource, importsSrc);
                    }

                    return true;
                }
            }

            return false;
        });

        return appHasUpdates;
    };

    const _resolveRelativeUrl = (src: string, relativeRoot: string): string => {
        const isRelative = src.startsWith('/') || src.startsWith('./') || src.startsWith('../');

        if (!isRelative) {
            return src;
        }

        return new URL(src, relativeRoot).href;
    };

    const _checkForAppHtmlUpdate = async (app: App): Promise<boolean> => {
        const nonceAllRegex = /\s*nonce=["'][^"']*["']/gi;
        const scriptImportmapRegex = /<script\s+[^>]*type=["']\s*importmap\s*["'][^>]*>[\s\S]*?<\/script>/i;
        const metaStaticFingerprintRegex = /<meta\s+[^>]*name=["']\s*o365-staticfiles-fingerprint\s*["'][^>]*>.*?<\/meta>/i;
        const metaDBObjectFingerprintRegex = /<meta\s+[^>]*name=["']\s*o365-dbobjectdefinition-fingerprint\s*["'][^>]*>.*?<\/meta>/i;

        const cache = await caches.open('app_html');
        const cacheResponse = await cache.match(new Request(`/nt/offline/${app.id}`));
        let cacheHtml = await cacheResponse?.text();

        const networkResponse = await fetch(`/nt/${app.id}`, { method: 'GET', headers: new Headers({ 'o365-workbox-strategy': 'NetworkOnly' }) });
        let networkHtml = await networkResponse.text();

        // Remove tags and attributes that constantly change to get a clear answer
        // for when the HTML has actually been changed
        cacheHtml = cacheHtml
            ?.replace(nonceAllRegex, '')
            .replace(scriptImportmapRegex, '')
            .replace(metaStaticFingerprintRegex, '')
            .replace(metaDBObjectFingerprintRegex, '');

        networkHtml = networkHtml
            .replace(nonceAllRegex, '')
            .replace(scriptImportmapRegex, '')
            .replace(metaStaticFingerprintRegex, '')
            .replace(metaDBObjectFingerprintRegex, '');

        const result = cacheHtml !== networkHtml;

        if (result && location.host === 'dev-test.omega365.com') {
            console.debug('HTML update found', cacheHtml, networkHtml);
        }

        return result;
    };

    const store: IPWAStore = {
        state: readonly(state),
        initialize,
        startSync,
        installApp,
        uninstallApp,
        uninstallAllApps,
        clearTables,
        updateApp,
        updateAppState,
        clearOfflineDataProc,
        cancelSync,
        setServiceWorkerRegistration,
        clearLocalData,
        setDebugUi,
        setDebugMergeProc,
        setSkipCheckIn,
        testProperty: 1,
        rerunSync,
        checkForAppUpdate,
        checkForAppsUpdate,
        eventEmitter,
        getServiceWorkerImportMap
    };

    return store;
})();
